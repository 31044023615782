import styled from '@emotion/styled';
export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.liteBlue};
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;

export const SignupButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
  max-width: 700px;
`;

export const Accounts = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
  padding: 0 4vw;
`;

export const Account = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  border: none;
  background-color: transparent;
  border-top: ${({ index }) => (index ? '1px' : '0px')} solid
    ${({ theme }) => theme.colors.gray1};
  transition: all 0.5s;
  cursor: pointer;

  justify-content: space-between;
  padding: 24px 0;
  outline: none;

  &:hover {
    background-color: #cccccc20;
  }
`;

export const NamesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InitialLetter = styled.div`
  font-size: 24px;
  font-weight: bold;
  background-color: ${({ color }) => color};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  ${({ withBorder }) =>
    withBorder &&
    `
  border: 1px solid black;
`}
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
`;
