import theme from './../../theme';
import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

// ICONS
import ActivityComplete from './icons/ActivityComplete';
import ArrowDown from './icons/ArrowDown';
import ArrowUp from './icons/ArrowUp';
import Attach from './icons/Attach';
import Backup from './icons/Backup';
import Bell from './icons/Bell';
import Bookmark from './icons/Bookmark';
import BrokenLamp from './icons/BrokenLamp';
import Burger from './icons/Burger';
import Calculator from './icons/Calculator';
import Calendar from './icons/Calendar';
import Checklist from './icons/Checklist';
import CircleCrown from './icons/CircleCrown';
import CircleQuestionMark from './icons/CircleQuestionMark';
import CircleStar from './icons/CircleStar';
import Clock from './icons/Clock';
import Close from './icons/Close';
import Communication from './icons/Communication';
import Cross from './icons/Cross';
import Crown from './icons/Crown';
import Cup from './icons/Cup';
import CupWithFlash from './icons/CupWithFlash';
import Darts from './icons/Darts';
import DefaultSkill from './icons/DefaultSkill';
import Diamond from './icons/Diamond';
import DigitalTools from './icons/DigitalTools';
import Discussion from './icons/Discussion';
import Documents from './icons/Documents';
import Edit from './icons/Edit';
import Email from './icons/Email';
import Eye from './icons/Eye';
import Facebook from './icons/Facebook';
import File from './icons/File';
import Flash from './icons/Flash';
import Flat from './icons/Flat';
import FolderEmail from './icons/FolderEmail';
import Hand from './icons/Hand';
import Heart from './icons/Heart';
import Help from './icons/Help';
import Knowledge from './icons/Knowledge';
import LeftRow from './icons/LeftRow';
import Light from './icons/Light';
import Link from './icons/Link';
import Logo from './icons/Logo';
import Mail from './icons/Mail';
import Minus from './icons/Minus';
import NotWorkingLink from './icons/NotWorkingLink';
import Notification from './icons/Notification';
import Office from './icons/Office';
import Pause from './icons/Pause';
import Photo from './icons/Photo';
import Planning from './icons/Planning';
import Plus from './icons/Plus';
import QuestionMark from './icons/QuestionMark';
import Quote from './icons/Quote';
import RightRow from './icons/RightRow';
import Sad from './icons/Sad';
import Scouts from './icons/Scouts';
import Search from './icons/Search';
import Share from './icons/Share';
import SkillComplete from './icons/SkillComplete';
import Smile from './icons/Smile';
import Spinner from './icons/Spinner';
import Success from './icons/Success';
import TextFile from './icons/TextFile';
import ThankYou from './icons/ThankYou';
import Tick from './icons/Tick';
import Trophy from './icons/Trophy';
import TrophyStar from './icons/TrophyStar';
import UploadCloud from './icons/UploadCloud';
import UserCheck from './icons/UserCheck';
import UserMinus from './icons/UserMinus';
import Whatsapp from './icons/Whatsapp';
import ScoutStack from './icons/ScoutStack';
import Info from './icons/Info';
import ExternalLink from './icons/ExternalLink';
import ChevronRight from './icons/ChevronRight';
import User from './icons/User';

export const IconMap = {
  activityComplete: ActivityComplete,
  email: Email,
  leftRow: LeftRow,
  planning: Planning,
  office: Office,
  brokenLamp: BrokenLamp,
  photo: Photo,
  file: File,
  textFile: TextFile,
  discussion: Discussion,
  share: Share,
  backup: Backup,
  calendar: Calendar,
  darts: Darts,
  checklist: Checklist,
  circleCrown: CircleCrown,
  circleStar: CircleStar,
  clock: Clock,
  close: Close,
  communication: Communication,
  cross: Cross,
  crown: Crown,
  flash: Flash,
  cup: Cup,
  diamond: Diamond,
  skillComplete: SkillComplete,
  thankYou: ThankYou,
  success: Success,
  tick: Tick,
  logo: Logo,
  burger: Burger,
  search: Search,
  scouts: Scouts,
  help: Help,
  rightRow: RightRow,
  trophy: Trophy,
  edit: Edit,
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  defaultSkill: DefaultSkill,
  whatsapp: Whatsapp,
  facebook: Facebook,
  mail: Mail,
  trophyStar: TrophyStar,
  calculator: Calculator,
  documents: Documents,
  folderEmail: FolderEmail,
  knowledge: Knowledge,
  notification: Notification,
  digitalTools: DigitalTools,
  sad: Sad,
  flat: Flat,
  smile: Smile,
  quote: Quote,
  userMinus: UserMinus,
  notWorkingLink: NotWorkingLink,
  hand: Hand,
  uploadCloud: UploadCloud,
  attach: Attach,
  questionMark: QuestionMark,
  link: Link,
  userCheck: UserCheck,
  heart: Heart,
  circleQuestionMark: CircleQuestionMark,
  bell: Bell,
  bookmark: Bookmark,
  cupWithFlash: CupWithFlash,
  eye: Eye,
  light: Light,
  minus: Minus,
  pause: Pause,
  plus: Plus,
  spinner: Spinner,
  scoutStack: ScoutStack,
  info: Info,
  externalLink: ExternalLink,
  chevronRight: ChevronRight,
  user: User,
};
const cursor = (pointer, disabled) => {
  if (disabled) {
    return 'not-allowed';
  }
  if (pointer) {
    return 'pointer';
  }
  return 'default';
};

const Parent = styled.div`
  ${setMargin}
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'flex-start'};
  cursor: ${({ pointer, disabled }) => cursor(pointer, disabled)};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const Icon = (props) => {
  const {
    icon,
    color,
    stroke,
    onClick,
    pointer,
    width,
    height,
    disabled,
    ...rest
  } = props;

  if (!IconMap[icon]) {
    // eslint-disable-next-line no-console
    console.warn(`<Icon /> called with invalid icon prop "${icon}"`);
    return null;
  }

  const StyledIcon = IconMap[icon];

  return (
    <Parent
      onClick={disabled ? undefined : onClick}
      pointer={pointer || onClick}
      disabled={disabled}
      {...rest}
    >
      <StyledIcon
        color={theme.colors[color] || color || 'currentColor'}
        stroke={stroke}
        width={width}
        height={height}
      />
    </Parent>
  );
};

export default Icon;
