import loadable from '@loadable/component';

const SuggestActivity = loadable(() => import('./SuggestActivity'));
const SuggestSuccess = loadable(() => import('./SuggestActivity/ThankYou'));
const ExampleFullScreen = loadable(() => import('./Example/ExampleFullScreen'));
const ResourceSpace = loadable(() => import('./ResourceSpace'));
const AboutDigitalChampion = loadable(() => import('./AboutDigitalChampion'));
const MeetOurDigitalChampion = loadable(() =>
  import('./MeetOurDigitalChampion')
);
const DigitalChampionTraining = loadable(() =>
  import('./DigitalChampionTraining')
);
const GuideToBeingADigitalChampion = loadable(() =>
  import('./GuideToBeingADigitalChampion')
);
const DigitalChampions = loadable(() => import('./DigitalChampions'));
const DigitalChampionDetails = loadable(() =>
  import('./DigitalChampionDetails')
);
const DigitalChampionDashboard = loadable(() =>
  import('./DigitalChampionDashboard')
);
const AllQuestions = loadable(() => import('./AllQuestions'));
const SolvedQuestions = loadable(() => import('./SolvedQuestions'));
const IndividualQuestion = loadable(() => import('./IndividualQuestion'));
const AnswerQuestion = loadable(() => import('./AnswerQuestion'));
const ThanksForAnswerQuestion = loadable(() =>
  import('./ThanksForAnswerQuestion')
);
const BankOfQuestions = loadable(() => import('./BankOfQuestions'));
const QuestionViaSkillOrActivity = loadable(() =>
  import('./QuestionViaSkillOrActivity')
);
const ThanksForAskQuestion = loadable(() => import('./ThanksForAskQuestion'));
const HqQuestions = loadable(() => import('./HqQuestions'));
const HqSuggestedActivities = loadable(() => import('./HqSuggestedActivities'));
const AssessmentLanding = loadable(() => import('./AssessmentLanding'));
const HqEditIndividualSkillArea = loadable(() => import('./HqEditSkillArea'));

// new sp3-extra
const SkillArea = loadable(() => import('./SkillArea'));
const ExpertBadge = loadable(() => import('./ExpertBadge'));

const BookmarkedSkillsPage = loadable(() => import('./BookmarkedSkillsPage'));
const Assessment = loadable(() => import('./Assessment'));

const HqVolunteersStats = loadable(() => import('./HqVolunteersStats'));
const HQLanding = loadable(() => import('./Landing/HQLanding'));
const VolunteerLanding = loadable(() => import('./Landing/VolunteerLanding'));
const TestLanding = loadable(() => import('./TestLanding'));
const HqSkillsProgress = loadable(() => import('./HqSkillsProgress'));
const HqIndividualSkill = loadable(() => import('./HqIndividualSkill'));
const HqEditIndividualSkill = loadable(() => import('./HqEditIndividualSkill'));
const VolunteerIndividualSkill = loadable(() =>
  import('./VolunteerIndividualSkill')
);
const VolunteerIndividualActivity = loadable(() =>
  import('./VolunteerIndividualActivity')
);
const VolunteerDashboard = loadable(() => import('./Dashboard/Volunteer'));
const SkillAreas = loadable(() => import('./SkillAreas'));
const IndividualSkillArea = loadable(() => import('./IndividualSkillArea'));
const HqDashboard = loadable(() => import('./HqDashboard'));
const SuccessUpdateActivity = loadable(() => import('./SuccessUpdateActivity'));
const RecommendedSkills = loadable(() => import('./RecommendedSkills'));
const HelpOthers = loadable(() => import('./HelpOthers'));
const MyAccount = loadable(() => import('./MyAccount'));
const ActivityCompleted = loadable(() => import('./ActivityCompleted'));
const TrainingProgress = loadable(() =>
  import('./ActivityCompleted/TrainingProgress')
);
const UpdateActivity = loadable(() => import('./UpdateActivity'));
const ErrorRoute = loadable(() => import('./ErrorRoutes'));
const CheckIn = loadable(() => import('./CheckIn'));
const CheckInAssessment = loadable(() => import('./CheckInAssessment'));
const RequestsForHelp = loadable(() => import('./RequestsForHelp'));
const ChampionAccount = loadable(() => import('./ChampionAccount'));
const FindSkills = loadable(() => import('./FindSkills'));

const Example = loadable(() => import('./Example'));
const Search = loadable(() => import('./Search'));

// const SSO = loadable(() => import('./SSO'));
const SSOSignupVolunteerNoSSO = loadable(() =>
  import('./SSO/Signup/VolunteerNoSSO')
);
const SSOSignupDigitalChampionNoSSO = loadable(() =>
  import('./SSO/Signup/DigitalChampionNoSSO')
);

const ChampionViewSingleRequest = loadable(() =>
  import('./ChampionViewSingleRequest')
);
const UnassignRequest = loadable(() =>
  import('./ChampionViewSingleRequest/UnassignRequest')
);
const RequestAChampion = loadable(() => import('./RequestAChampion'));
const RequestAChampionSuccess = loadable(() =>
  import('./RequestAChampion/RequestAChampionSuccess')
);
const ReviewSuggestActivity = loadable(() => import('./ReviewSuggestActivity'));
const ReviewSuggestSuccess = loadable(() =>
  import('./ReviewSuggestActivity/ThankYou')
);

export {
  Example,
  SSOSignupVolunteerNoSSO,
  SSOSignupDigitalChampionNoSSO,
  TestLanding,
  Assessment,
  HqVolunteersStats,
  HQLanding,
  VolunteerLanding,
  HqSkillsProgress,
  SuccessUpdateActivity,
  VolunteerDashboard,
  HqDashboard,
  Search,
  HqIndividualSkill,
  HqEditIndividualSkill,
  VolunteerIndividualSkill,
  VolunteerIndividualActivity,
  HelpOthers,
  SkillAreas,
  IndividualSkillArea,
  MyAccount,
  ActivityCompleted,
  TrainingProgress,
  RecommendedSkills,
  UpdateActivity,
  ErrorRoute,
  CheckIn,
  CheckInAssessment,
  ExampleFullScreen,
  RequestAChampion,
  RequestAChampionSuccess,
  // champion pages
  RequestsForHelp,
  FindSkills,
  ResourceSpace,
  AboutDigitalChampion,
  MeetOurDigitalChampion,
  DigitalChampionTraining,
  ChampionViewSingleRequest,
  UnassignRequest,
  ChampionAccount,
  GuideToBeingADigitalChampion,
  DigitalChampions,
  DigitalChampionDetails,
  DigitalChampionDashboard,
  AllQuestions,
  SolvedQuestions,
  IndividualQuestion,
  AnswerQuestion,
  ThanksForAnswerQuestion,
  BankOfQuestions,
  QuestionViaSkillOrActivity,
  ThanksForAskQuestion,
  HqQuestions,
  SuggestActivity,
  SuggestSuccess,
  HqSuggestedActivities,
  ReviewSuggestActivity,
  ReviewSuggestSuccess,
  AssessmentLanding,
  SkillArea,
  ExpertBadge,
  BookmarkedSkillsPage,
  HqEditIndividualSkillArea,
};
