import axios from 'axios';
import handleError from './format-error';

const USERS_BASE = '/users';

const getUserById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${USERS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getLoggedInUserInfo = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${USERS_BASE}/my-info`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const signup = async (form, { options } = {}) => {
  try {
    const { data } = await axios.post(`${USERS_BASE}/signup`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const login = async (form, { options } = {}) => {
  try {
    const { data } = await axios.post(`${USERS_BASE}/login`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const logout = async ({ options } = {}) => {
  try {
    await axios.post(`${USERS_BASE}/logout`);
    return {};
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getUserDashboard = async ({ options } = {}) => {
  try {
    const data = await axios.get(`${USERS_BASE}/dashboard`);
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deleteUserById = async ({ options } = {}) => {
  try {
    const data = await axios.delete(`${USERS_BASE}`);
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const resetPassword = async ({ email, options } = {}) => {
  try {
    const data = await axios.post(`${USERS_BASE}/reset-password`, { email });
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getMyAccount = async ({ options } = {}) => {
  try {
    const data = await axios.get(`${USERS_BASE}/my-account`);
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const setPassword = async ({ password, token, options } = {}) => {
  try {
    const data = await axios.post(`${USERS_BASE}/set-password`, {
      password,
      token,
    });
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const changePassword = async ({ oldPassword, newPassword, options } = {}) => {
  try {
    const data = await axios.put(`${USERS_BASE}/password`, {
      oldPassword,
      newPassword,
    });
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateMyAccount = async ({ body, options } = {}) => {
  try {
    const data = await axios.patch(`${USERS_BASE}/my-account`, body);
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const storeAssessmentScore = async ({ body, options } = {}) => {
  try {
    const data = await axios.post(`${USERS_BASE}/assessment`, body);
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getUserByMembershipNo = async ({ membershipNo, options } = {}) => {
  try {
    const data = await axios.get(
      `${USERS_BASE}/membership-number/${membershipNo}`
    );
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getUserById,
  getLoggedInUserInfo,
  signup,
  login,
  logout,
  getUserDashboard,
  deleteUserById,
  resetPassword,
  setPassword,
  getMyAccount,
  updateMyAccount,
  storeAssessmentScore,
  changePassword,
  getUserByMembershipNo,
};
