import React, { useEffect } from 'react';
import { Route as RouterRoute, Redirect, useLocation } from 'react-router-dom';
import { differenceInDays } from 'date-fns';

import Layout from './../../components/Layout';
import { navRoutes, roles } from '../../constants/';

import { authorization } from '../../helpers';
import { useAuth } from '../../context/auth';

const Route = (props) => {
  const {
    publicOnly,
    isPrivate,
    layout,
    path,
    Component,
    exact,
    allowedRoles,
  } = props;

  const { user } = useAuth();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (publicOnly && user?.id) {
    switch (user.role) {
      case roles.VOLUNTEER:
        return <Redirect to={navRoutes.VOLUNTEER.DASHBOARD} />;
      case roles.DIGITAL_CHAMPION:
        return <Redirect to={navRoutes.DIGITAL_CHAMPION.DASHBOARD} />;
      case roles.HQ:
        return <Redirect to={navRoutes.HQ.DASHBOARD} />;

      default:
        break;
    }
  }

  if (user.role === roles.VOLUNTEER) {
    const timeNow = new Date();
    const timeLastCheckIn = new Date(user.lastCheckIn);
    const difference = differenceInDays(timeNow, timeLastCheckIn);

    if (difference >= 7 && !path.includes(navRoutes.VOLUNTEER.CHECK_IN)) {
      return <Redirect to={navRoutes.VOLUNTEER.CHECK_IN} />;
    }
  }
  if (isPrivate) {
    const authorized = authorization(user.role, allowedRoles);

    if (user.id) {
      return (
        <RouterRoute path={path} props exact={exact}>
          <Layout layout={layout} {...props}>
            {authorized ? (
              <Component {...props} />
            ) : (
              <Redirect to={navRoutes.GENERAL.UNAUTHORIZED} {...props} />
            )}
          </Layout>
        </RouterRoute>
      );
    }
    return <Redirect to={navRoutes.GENERAL.HOME} />;
  }

  return (
    <RouterRoute path={path} props exact={exact}>
      <Layout layout={layout} {...props}>
        <Component {...props} />
      </Layout>
    </RouterRoute>
  );
};

export default Route;
