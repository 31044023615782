import { useEffect, createContext, useContext, useReducer } from 'react';
import { Users } from '../api-calls';
import { LoginAndSignupPrompt, SelectAccount } from '../components/SSOModals';
import { useHistory } from 'react-router-dom';
import { useAuth } from './auth';
import { useMsal } from '@azure/msal-react';
import { roles, navRoutes } from '../constants';
import { handleClickForAnalytics } from '../helpers';
import moment from 'moment';
import Loading from '../components/Loading';
import { loginRequest } from '../helpers/auth-config';
// Define user actions
const ACTIONS = {
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_INTENT: 'SET_INTENT',
  SET_SELECT_ACCOUNT_MODAL: 'SET_SELECT_ACCOUNT_MODAL',
  SET_LOGIN_AND_SIGNUP_PROMPT: 'SET_LOGIN_AND_SIGNUP_PROMPT',
};

// Initial state
const initialState = {
  intent: null, // Tracks intent (login/signup) and role
  loading: true,
  error: null,
  selectAccountModalOpen: false,
  loginAndSignupPrompt: null, // Tracks login and signup prompt {type: 'login'/'signup', errorContent: 'error message'}
};

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload };
    case ACTIONS.SET_ERROR:
      return { ...state, error: action.payload };
    case ACTIONS.SET_INTENT:
      return { ...state, intent: action.payload };
    case ACTIONS.SET_SELECT_ACCOUNT_MODAL:
      return { ...state, selectAccountModalOpen: action.payload };
    case ACTIONS.SET_LOGIN_AND_SIGNUP_PROMPT:
      return { ...state, loginAndSignupPrompt: action.payload };
    default:
      return state;
  }
};

const getRedirectPath = (data) => {
  if (data.role === roles.VOLUNTEER) {
    const diff = moment().diff(moment(data.lastCheckIn), 'days');
    return diff >= 7
      ? navRoutes.VOLUNTEER.CHECK_IN
      : navRoutes.VOLUNTEER.DASHBOARD;
  } else if (data.role === roles.DIGITAL_CHAMPION) {
    return navRoutes.DIGITAL_CHAMPION.DASHBOARD;
  }
  return navRoutes.HQ.DASHBOARD;
};

const SSOContext = createContext({
  SSOResponse: null,
  SSOLogin: () => {},
  SSOLogout: () => {},
  volunteerSignup: () => {},
  digitalChampionSignup: () => {},
});

const SSOProvider = (props) => {
  const { instance, accounts } = useMsal();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setUser } = useAuth();
  const history = useHistory();

  const loginUserInApp = async (tokenResponse) => {
    const { idToken } = tokenResponse;

    const { data, error } = await Users.login({ idToken });

    if (error) {
      handleClickForAnalytics({
        name: 'login_error',
        category: 'Login',
        action: `Login_errors_${data?.role}`,
      });

      setIntent(null);
    } else {
      setUser(data);

      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      handleClickForAnalytics({
        category: 'Login',
        action: `Login_success_${data.role}`,
      });

      const redirectPath = getRedirectPath(data);
      history.push(redirectPath);
    }
  };

  const promptForSignup = () => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    dispatch({ type: ACTIONS.SET_SELECT_ACCOUNT_MODAL, payload: false });
    dispatch({
      type: ACTIONS.SET_LOGIN_AND_SIGNUP_PROMPT,
      payload: {
        type: 'login',
        errorContent:
          'We could not find your account on the Digital Skills Platform. Please sign up to continue.',
      },
    });
  };

  const promptForLogin = () => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    dispatch({ type: ACTIONS.SET_SELECT_ACCOUNT_MODAL, payload: false });
    dispatch({
      type: ACTIONS.SET_LOGIN_AND_SIGNUP_PROMPT,
      payload: {
        type: 'signup',
        errorContent:
          'An account with this membership already exists. Please log in instead.',
      },
    });
  };

  const handleSignup = async (tokenResponse, signupRole) => {
    const { idToken } = tokenResponse;
    history.push({
      pathname:
        signupRole === roles.VOLUNTEER
          ? navRoutes.VOLUNTEER.SIGNUP
          : navRoutes.DIGITAL_CHAMPION.SIGNUP,
      state: { idToken },
    });
  };

  const handlePostCheckUserInDB = async (user, tokenResponse) => {
    // Retrieve state (intent and role) after redirect
    const userState = tokenResponse?.state
      ? JSON.parse(tokenResponse.state)
      : null;

    if (userState?.action === 'login') {
      if (user) return await loginUserInApp(tokenResponse);
      return promptForSignup();
    } else if (userState?.action === 'signup') {
      if (user) return promptForLogin();
      return await handleSignup(tokenResponse, userState?.role);
    }
  };

  const handlePostLogin = async (tokenResponse) => {
    const { idTokenClaims } = tokenResponse;
    const { extension_MembershipNumber } = idTokenClaims;

    const { data: user } = await Users.getUserByMembershipNo({
      membershipNo: extension_MembershipNumber,
    });

    handlePostCheckUserInDB(user, tokenResponse);
  };
  const acquireTokenSilent = async (account, state) => {
    try {
      dispatch({ type: ACTIONS.SET_LOADING, payload: true });
      const response = await instance.acquireTokenSilent({
        ...loginRequest(state),
        account,
      });
      handlePostLogin(response);
    } catch (error) {
      if (error.errorCode === 'interaction_required') {
        instance.loginRedirect({ ...loginRequest(state), account });
      } else {
        dispatch({ type: ACTIONS.SET_ERROR, payload: error.message });
      }
    } finally {
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    }
  };

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((response) => {
        if (response) {
          // Retrieve state (intent and role) after redirect
          const userState = response?.state ? JSON.parse(response.state) : null;
          dispatch({ type: ACTIONS.SET_INTENT, payload: userState });
          acquireTokenSilent(response.account || accounts[0], userState);
        } else {
          dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        }
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.SET_ERROR, payload: error.message });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, accounts]);

  const setIntent = (action, role = null) => {
    const intent = { action, role };
    dispatch({ type: ACTIONS.SET_INTENT, payload: intent });
  };

  const SSOLoginRedirect = (state) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    instance.loginRedirect({
      ...loginRequest(state),
      prompt: 'select_account',
    });
  };

  const onClickLoginButton = () => {
    setIntent('login');
    if (accounts.length > 0) {
      dispatch({ type: ACTIONS.SET_SELECT_ACCOUNT_MODAL, payload: true });
    } else {
      SSOLoginRedirect({ action: 'login' });
    }
  };

  const onSelectAccount = (account) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    account
      ? acquireTokenSilent(account, state.intent)
      : SSOLoginRedirect(state.intent);
    dispatch({ type: ACTIONS.SET_SELECT_ACCOUNT_MODAL, payload: false });
  };

  const logout = async () => {
    try {
      instance.setActiveAccount(null);
      const { error } = await Users.logout();
      if (!error) {
        history.push(navRoutes.GENERAL.HOME);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        dispatch({ type: ACTIONS.SET_INTENT, payload: null });
        setUser({});
        handleClickForAnalytics({
          name: 'logout',
          category: 'Logout',
          action: 'Logout (success)',
        });
      }
    } catch (err) {
      handleClickForAnalytics({
        name: 'logout',
        category: 'Logout',
        action: `Logout errors`,
      });
      console.error(err);
    }
  };

  const handleClickOnSignup = (role) => {
    setIntent('signup', role);

    if (accounts.length > 0) {
      dispatch({ type: ACTIONS.SET_SELECT_ACCOUNT_MODAL, payload: true });
    } else {
      SSOLoginRedirect({
        action: 'signup',
        role,
      });
    }
  };

  const onLoginAfterSignup = () => {
    dispatch({
      type: ACTIONS.SET_LOGIN_AND_SIGNUP_PROMPT,
      payload: null,
    });
    onClickLoginButton();
  };

  const value = {
    login: onClickLoginButton,
    logout,
    volunteerSignup: () => handleClickOnSignup(roles.VOLUNTEER),
    digitalChampionSignup: () => handleClickOnSignup(roles.DIGITAL_CHAMPION),
  };

  return (
    <SSOContext.Provider value={value} {...props}>
      {!state.loading ? (
        <>
          <LoginAndSignupPrompt
            errorModalType={state?.loginAndSignupPrompt?.type}
            setIsErrorModalOpen={() =>
              dispatch({
                type: ACTIONS.SET_LOGIN_AND_SIGNUP_PROMPT,
                payload: null,
              })
            }
            handleLogin={onLoginAfterSignup}
            errorModalContent={state?.loginAndSignupPrompt?.errorContent}
            account={instance.getActiveAccount()}
          />

          <SelectAccount
            open={state.selectAccountModalOpen}
            setOpen={() => {
              dispatch({
                type: ACTIONS.SET_SELECT_ACCOUNT_MODAL,
                payload: false,
              });
            }}
            accounts={accounts}
            onSelectAccount={onSelectAccount}
          />

          {props.children}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <Loading pageLoading={true} />
        </div>
      )}
    </SSOContext.Provider>
  );
};

const useSSO = () => useContext(SSOContext);

export { SSOProvider, useSSO };
export default SSOContext;
