import { fields, createSchema, validate as _validate } from '..';

import { roles } from '../../constants';

const { VOLUNTEER } = roles;
const { trueBoolean } = fields;

const volunteer = createSchema({
  completed: trueBoolean,
});

const validate = (data) => {
  if (data.role === VOLUNTEER) return _validate(volunteer, data);
};

export default validate;
