import * as S from './style';
import Modal from '../Modal';
import Icon from '../Icon';
import * as T from '../Typography';

const colors = ['blue', 'teal', 'purple', 'litestGray'];
const SelectAccount = ({ open, setOpen, accounts, onSelectAccount }) => {
  return (
    <Modal open={open} setOpen={setOpen} maxWidth={'800px'}>
      <S.ModalContent>
        <T.H2 override="h4" ta="center" mt={4} mb={4}>
          Choose an account
        </T.H2>

        <T.BodyR mb={6}>
          Please select an account to continue to the Digital Skills portal
        </T.BodyR>
        <S.Accounts>
          {accounts?.map((account, i) => {
            const { username, idTokenClaims } = account || {};
            const { extension_PreferredName: preferredName, family_name } =
              idTokenClaims || {};
            const initial = (preferredName || username || family_name || '')[0];
            const color = colors[i % colors.length];

            return (
              <S.Account
                key={i}
                index={i}
                onClick={() => {
                  onSelectAccount(account);
                }}
              >
                <S.Right>
                  <S.InitialLetter color={color}>{initial}</S.InitialLetter>
                  <S.NamesWrapper>
                    <T.BodyR>
                      {preferredName} {family_name}
                    </T.BodyR>
                    <T.BodyR>{username}</T.BodyR>
                  </S.NamesWrapper>
                </S.Right>
                <S.Left>
                  <Icon
                    icon="chevronRight"
                    color="black"
                    width="24px"
                    height="24px"
                  />
                </S.Left>
              </S.Account>
            );
          })}

          <S.Account
            index={1}
            onClick={() => {
              onSelectAccount();
            }}
          >
            <S.Right>
              <S.InitialLetter color={'white'} withBorder>
                <Icon icon="user" color="black" width="24px" height="24px" />
              </S.InitialLetter>
              <S.NamesWrapper>
                <T.BodyR>Use another account</T.BodyR>
                <T.BodyR>
                  Click here to sign in with a different account
                </T.BodyR>
              </S.NamesWrapper>
            </S.Right>
            <S.Left>
              <Icon
                icon="chevronRight"
                color="black"
                width="24px"
                height="24px"
              />
            </S.Left>
          </S.Account>
        </S.Accounts>
      </S.ModalContent>
    </Modal>
  );
};

export default SelectAccount;
