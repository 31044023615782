import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';

export const Field = styled.div`
  ${setMargin};
  width: ${({ w }) => w || '100%'};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  .ant-slider-track {
    background-color: ${({ theme }) => theme.colors.black};
  }

  .ant-slider-rail {
    background-color: ${({ theme }) => theme.colors.black};
  }

  .ant-slider-dot-active {
    border-color: ${({ theme, color }) =>
      color ? theme.colors[color] || color : theme.colors.teal};
  }

  .ant-slider-dot {
    border-color: ${({ theme, color }) =>
      color ? theme.colors[color] || color : theme.colors.teal};
    top: -4px;
    width: 12px;
    height: 12px;
    display: none;
  }

  .ant-slider-handle {
    border-color: ${({ theme, color }) =>
      color ? theme.colors[color] || color : theme.colors.teal};
    background: ${({ theme, color }) =>
      color ? theme.colors[color] || color : theme.colors.teal};
    width: 20px;
    height: 20px;
    margin-top: -8px;
    border-radius: 50%;

    ::after,
    ::before {
      display: none;
    }
  }

  .ant-slider {
    :hover {
      .ant-slider-track {
        background-color: ${({ theme }) => theme.colors.liteGray};
      }

      .ant-slider-rail {
        background-color: ${({ theme }) => theme.colors.liteGray};
      }

      .ant-slider-handle {
        border-color: ${({ theme, color }) =>
          color ? theme.colors[color] || color : theme.colors.teal};
      }

      .ant-slider-dot-active {
        border-color: ${({ theme, color }) =>
          color ? theme.colors[color] || color : theme.colors.teal};
      }

      .ant-slider-dot {
        border-color: ${({ theme, color }) =>
          color ? theme.colors[color] || color : theme.colors.teal};
        top: -4px;
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const IndicatorsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  svg:last-child {
    transform: translateX(10px);
  }
`;
